import React, {useEffect, useState} from "react";
import {Box, Dialog, DialogContent, IconButton, Paper, Stack, Tooltip, Typography} from "@mui/material";
import questionType from "../assets/data/typeMap";
import {QuestionSwitch} from "./Questions/QuestionSwitch";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Close, LockOutlined} from "@mui/icons-material";
import pleshDark from "../theme/pleshDark";
import toolpressSong from "../assets/images/home-question-img/toolpress-songs.png";
import altroIcon from "../assets/images/home-question-img/altroIcon.png"
import altroIconDisabled from "../assets/images/home-question-img/altroIconDisabled.png"

export const PinnedQuestions = ({userData, questions}) => {
    const [openTooltip, setOpenTooltip] = useState(false)
    const [selected, setSelected] = useState('')
    const {id} = useParams()

    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(() => {
        selected !== '' ? getElementPlaying() : setElementPlaying({})
    }, [selected]);

    function getElementPlaying() {
        firestore.collection('users/plesh/events/'+id+'/queue').doc(selected).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }

    return (
        <Box mt={7} /*mt={'210px'}*/>
            {selected !== '' &&
                <Dialog open={selected !== ''} onClose={() => setSelected('')} maxWidth={'md'}
                        fullWidth={true}
                        fullScreen={elementPlaying.tipo === 'color' || elementPlaying.tipo === 'altro'}
                >
                <DialogContent id={'a'+selected} /*id={'pinnedQuestionDialogBg'}*/
                               sx={elementPlaying.tipo === 'altro' ? {
                                   paddingX: 0,
                                   backgroundColor: 'white',
                                   color: 'black',
                               } : ''}>
                    <Box sx={{textAlign:'right'}}>
                        <IconButton onClick={() => setSelected('')}
                                    sx={{
                                        color: (elementPlaying.tipo === 'color' || elementPlaying.tipo === 'altro')
                                            ? pleshDark.palette.getContrastText(elementPlaying.color || '#fff') : '',
                                        zIndex: 100
                                    }}
                        >
                            <Close/>
                        </IconButton>
                    </Box>
                    {elementPlaying.tipo !== 'altro' && 
                        <Typography textAlign={'center'} variant={'h5'}
                                 sx={{color: elementPlaying.tipo === 'color' ? pleshDark.palette.getContrastText(elementPlaying.color) : ''}}
                        >
                            {elementPlaying.domanda}
                        </Typography>}
                    <QuestionSwitch elementPlaying={elementPlaying} displayName={userData.displayName}/>
                </DialogContent>
            </Dialog>}
            <Stack direction={'row'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'center'}>
                {questions.docs.sort((a, b) => a.data().position - b.data().position)
                    .map((button) => {
                        const onlyBeaStory = questions.docs.length === 1
                        const beaStoriesLocked = false//userData.displayName !== 'macca' && button.data().tipo === 'altro' && button.data().disabled
                        return <Box component={Paper} elevation={22} m={1} width={onlyBeaStory ? '65%' : '35%'}
                             borderRadius={'18px'} border={beaStoriesLocked ? "3px solid rgba(255,255,255,0.5)" : null}
                             onClick={() => {
                                 if(!beaStoriesLocked)
                                     setSelected(button.id)
                                 else
                                     setOpenTooltip(!openTooltip)
                             }}
                             sx={{aspectRatio: '1/1', background: pleshDark.palette.background.default}} position={'relative'}>
                                <Box sx={{background: beaStoriesLocked ?
                                        pleshDark.palette.background.default : 'white', width: '100%',
                                }}
                                     minHeight={'50%'}
                                     display={'flex'} justifyContent={'left'} alignItems={'center'}
                                     px={1}
                                     borderRadius={'18px 18px 0 0'}
                                >
                                    <Stack>
                                        <Typography variant={(onlyBeaStory) ? 'h4' : 'body2'}
                                                    color={(beaStoriesLocked) ? 'white' : 'black'}
                                                    fontFamily={'Forever Grotesk'} fontWeight={'light'}
                                                    textAlign={'left'} maxWidth={onlyBeaStory ? '195px' : '95px'}
                                        >
                                            {button?.data()?.domanda || ''}
                                        </Typography>
                                        {/*button?.data()?.tipo === 'altro' && <img src={beaStoriesLocked ? altroIconDisabled : altroIcon} width={'20px'}/>*/}
                                    </Stack>
                                </Box>
                                <Box sx={{
                                    background: pleshDark.palette.background.default,
                                    width: '100%',
                                    position: 'absolute',
                                    bottom: 0
                                }}
                                     minHeight={'50%'}
                                     borderRadius={'0 0 18px 18px'}
                                >
                                    <Box sx={{position: 'absolute', top: 'unset', bottom: -6, left: 0, right: 0}}>
                                        {button.id === "ILkKng4qkPIB36hDuLG9" ?
                                            <img src={toolpressSong} width={'95%'}/>
                                            : questionType[button.data().tipo].img || questionType[button.data().tipo].icon}
                                    </Box>
                                </Box>
                            {beaStoriesLocked &&
                                <Tooltip open={openTooltip} arrow title={<div style={{textAlign: 'center', fontSize: '13px'}}>Le tue BEA Stories non sono ancora attive.
                                    <br/>Dopo le premiazioni torna qui per condividere i premi vinti su tutti i tuoi canali social. 
                                </div>}>
                                    <IconButton sx={{mt: '-13%'}} onClick={() => setOpenTooltip(!openTooltip)}>
                                        <LockOutlined sx={{fontSize: '45px', color: 'rgba(255,255,255,0.65)'}}/>
                                    </IconButton>
                                </Tooltip>}
                        </Box>
                    })
                }
            </Stack>
            {/*<Grid px={1} container justifyContent={'center'} width={'100%'} minHeight={'200px'} /* spacing={1}>
                {questions.docs.sort((a, b) => a.data().position - b.data().position)
                    .map((button) => (
                        <Grid key={button.id} item xs={6} sx={{cursor: 'pointer'}}
                              onClick={() => setSelected(button.id)}
                        >
                            <Box mb={1} mx={1} position={'relative'}>
                                <Box sx={{background: 'white', width: '100%',}} minHeight={'100px'}
                                     borderRadius={'0 18px'}>
                                    <Box sx={{position: 'absolute', top: !!button.img ? '10%' : '10%', left: 0, right: 0}}>
                                        {button.img || button.icon || questionType[button.data().tipo].img || questionType[button.data().tipo].icon}
                                    </Box>
                                </Box>
                                <Box sx={{
                                    background: pleshDark.palette.accent.main,
                                    width: '100%',
                                    position: 'absolute',
                                    bottom: 0
                                }}
                                     minHeight={'25px'}
                                     borderRadius={'0 18px'}>
                                    <Typography variant={'caption'} fontStyle={'italic'} fontWeight={'bold'}
                                                textTransform={'none'} color={'white'}>
                                        {button?.data()?.domanda || ''}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>))}
            </Grid>*/}
            {/*<Stack spacing={4}>
                {
                    questions.docs.sort((a, b) => a.data().position - b.data().position).map((q, i) => {
                            return <Button key={i} variant={'home-question'} startIcon={questionType[q.data().tipo].icon}
                                           sx={{
                                               background: q.data().tipo === 'color' ? q.data().color : '',
                                               color: q.data().tipo === 'color' ? pleshDark.palette.getContrastText(q.data().color) : ''
                                            }}
                                    onClick={() => setSelected(q.id)}>
                                {q.data().domanda}
                            </Button>
                        }
                    )
                }
            </Stack>*/}
        </Box>
    )
}