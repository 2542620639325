export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const BUCKET = "https://storage.googleapis.com/bea-2024"

//export const BEA_API = "https://matilde-api-dev.plesh.co"
//export const BEA_API_23 = "https://bea-2023-api-5dl3sjiv3q-ew.a.run.app"
export const BEA_API = "https://bea-2024-api-719785460120.europe-west1.run.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#EF7B22', '#EF7B22'],
        home_question: ['#EF7B22', '#EF7B22'],
        applausometro: ['#EF7B22', '#EF7B22'],
        siNo:['#EF7B22', '#EF7B22'],
        sentiment: ['#EF7B22', '#EF7B22'],
        wordcloud: ['#EF7B22', '#EF7B22'],
        qna: ['#EF7B22', '#EF7B22'],
    },
    primary: '#EF7B22',
    secondary: '#263238',
    accent: '#EF7B22',
    text: '#eceff1',
    background:"#1D1D1B",
    poll:{
        default:"rgba(239,123,34,0.4)",
        answered:"#EF7B22",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}