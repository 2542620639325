import React from "react";
import {Box, Slide, Stack} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";
import {emojisplosion} from "emojisplosion";
import {API_URL} from "../config";
import {useMediaQuery} from "@material-ui/core";
import heart from '../assets/images/reactions/heart.png'
import smile from '../assets/images/reactions/smile.png'
import party from '../assets/images/reactions/party.png'
import surprised from '../assets/images/reactions/surprised.png'
import pleshDark from "../theme/pleshDark";

const ReactionsLive = ({url, idPlaying, eventPlaying, user}) => {
    const {id} = useParams()

    async function postDataApplausi(e, type, emoji) {
        emojisplosion({
            emojiCount:5,
            emojis: [emoji],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        await axios.post(`${API_URL}/${id}/reactions?type=${type}`, {user: user.uid})
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    const ButtonItem = ({type, img, emoji, color}) => (
        <Box id='clapButton' disabled={!eventPlaying} onClick={(e) => postDataApplausi(e, type, emoji)}
             justifyContent={'center'} alignItems={'center'}
             //pt={'12px'}
             //borderRadius={"0 16px"} border={'1.5px solid '+color}
             sx={{
                 //bgcolor: pleshDark.palette.accent.main,//color,
                 width: 65,
                 height: 65,
                 cursor: 'pointer',
                 //boxShadow: '6px 5px 6px 0px #37373761'
             }}x
        >
            <img style={{margin: 'auto', height:'45px', boxShadow: `${pleshDark.palette.primary.main} 1px 1px 5px 1px`, borderRadius: '50%'}} src={img} />
        </Box>
    )

    const matches = useMediaQuery('(max-height:680px)');

    return (
        <Stack sx={{zIndex:100}} alignItems={'center'} mt={3} pb={5} width={'100%'} px={4} //position={'absolute'} bottom={'10%'} left={0} right={0}
        >
            {/*<Typography gutterBottom variant={'caption'}>Send your reactions</Typography>*/}
            <Slide in direction={"up"} timeout={1000}>
                <Stack direction={'row'} display={'flex'} justifyContent={'space-between'} spacing={1}>
                    {
                        reactions.map((reaction) => (
                            <ButtonItem key={reaction.id} type={reaction.id} img={reaction.img} emoji={reaction.emoji} color={reaction.color}/>
                        ))
                    }
                </Stack>
            </Slide>
        </Stack>
    )
}
export default ReactionsLive

const reactions = [
    {
        id: 'smile',
        emoji: '😄',
        img: smile
    },
    {
        id: 'heart',
        emoji: '❤️',
        img: heart,
        color: '#fde357'
    },
    {
        id: 'party',
        emoji: '🎉️',
        img: party
    },
    {
        id: 'surprised',
        emoji: '😮',
        img: surprised
    },
]